import React from 'react';
import {
  DeprecatedColorDataTableCode,
  Link,
  Paragraph,
  PlatformTab,
  Section,
} from 'docComponents';

const AppleTab = ({ data }) => {
  const deprecatedData = data.allColorsYaml.edges.filter(
    node => node.node.type === 'Deprecations'
  );

  return (
    <PlatformTab>
      <Section title="Colors">
        <Paragraph>
          Deprecated in:{' '}
          <Link href="https://github.com/hudl/ios-uniform-ui/releases/tag/5.1.0">
            v5.1.0
          </Link>
        </Paragraph>
        <Paragraph>Scheduled to be removed in: v6.0.0</Paragraph>
        <DeprecatedColorDataTableCode data={deprecatedData} platform="apple" />
      </Section>
    </PlatformTab>
  );
};

export default AppleTab;
