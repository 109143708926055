import React from 'react';
import {
  CodeSnippet,
  DataTable,
  DataTableRow,
  DataTableHeader,
  DataTableBody,
  DataTableValueBlock,
  DataTableCodeBlock,
  DeprecatedColorDataTableCode,
  Link,
  Paragraph,
  PlatformTab,
  Section,
} from 'docComponents';

const WebTab = ({ data }) => {
  const deprecatedData = data.allColorsYaml.edges.filter(
    node => node.node.type === 'Deprecations'
  );

  return (
    <PlatformTab>
      <Section title="About Deprecations">
        <Paragraph>
          As Uniform evolves, there are instances when existing variables need
          to be deprecated (and eventually removed). We’ll never deprecate or
          remove a variable without advance warning. Release notes, Slack
          messages and the casual shout from a rooftop will all come before a
          variable is even deprecated.
        </Paragraph>

        <Paragraph>
          Because a deprecated variable remains in the system, your code should
          continue to build as expected. However, once it’s removed with the
          next major release, you will need to update to the stable variable.
          We’ll be sure to communicate those below.
        </Paragraph>

        <Paragraph>
          For web, all deprecated variables reside in a specific{' '}
          <code>var.deprecated</code> file. You’ll have to manually add that
          file to all <code>.scss</code> partials in any code base utilizing
          those variables.
        </Paragraph>

        <CodeSnippet
          code="@import '~/hudl-uniform-ui-css/source/scss/variables/var.deprecated';"
          platform="css"
          disableCodeEditing
        />
      </Section>

      <Section title="Breakpoints">
        <Paragraph>
          Deprecated in:{' '}
          <Link href="https://github.com/hudl/uniform-ui-css/releases/tag/v1.10.0">
            v1.10.0
          </Link>
        </Paragraph>
        <Paragraph>Scheduled to be removed in: v2.0.0</Paragraph>
        <DataTable>
          <DataTableHeader>
            <DataTableRow layout="split">
              <th>Removed Variable</th>
              <th>Stable Variable</th>
            </DataTableRow>
          </DataTableHeader>
          <DataTableBody>
            <DataTableRow layout="split">
              <DataTableValueBlock>$bp-small</DataTableValueBlock>
              <DataTableCodeBlock>No direct mapping</DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="split">
              <DataTableValueBlock>$bp-medium</DataTableValueBlock>
              <DataTableCodeBlock>No direct mapping</DataTableCodeBlock>
            </DataTableRow>
          </DataTableBody>
        </DataTable>
      </Section>
      <Section title="Colors">
        <Paragraph>
          Deprecated in:{' '}
          <Link href="https://github.com/hudl/uniform-ui-css/releases/tag/v1.0.0">
            v1.0.0
          </Link>
        </Paragraph>
        <Paragraph>Scheduled to be removed in: v2.0.0</Paragraph>
        <DeprecatedColorDataTableCode data={deprecatedData} platform="web" />
      </Section>
    </PlatformTab>
  );
};

export default WebTab;
