import React from 'react';
import {
  DeprecatedColorDataTableCode,
  Link,
  Paragraph,
  PlatformTab,
  Section,
} from 'docComponents';

const ReactNativeTab = ({ data }) => {
  const deprecatedData = data.allColorsYaml.edges.filter(
    node => node.node.type === 'Deprecations'
  );

  return (
    <PlatformTab>
      <Section title="Colors">
        <Paragraph>
          Deprecated in:{' '}
          <Link href="https://github.com/hudl/rn-uniform-ui/releases/tag/v4.0.0">
            v4.0.0
          </Link>
        </Paragraph>
        <Paragraph>Scheduled to be removed in: v5.0.0</Paragraph>
        <DeprecatedColorDataTableCode
          data={deprecatedData}
          platform="reactnative"
        />
      </Section>
    </PlatformTab>
  );
};

export default ReactNativeTab;
